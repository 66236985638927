import axios from "axios";

export class customer
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.name = null;
        this.rfc = null;
        this.address = null;
        this.postal_code = null;
        this.country = "México";
        this.state = null;
        this.municipality = null;
        this.suburb = null;
        this.email = null;
        this.phone = null;
        this.tax_regime = null;
        this.created = null;
        this.created_by = null;
        this.modified = null;
        this.modified_by = null;
        this.use_cfdi = null;
    }

    async zip(zip) {
        let response = await axios.get("tpv/customer/zip/" + zip, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async all() {
        let response = await axios.get("tpv/customer", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async retrieve() {
        let response = await axios.get("tpv/customer/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async save() {
        let response = await axios.post("tpv/customer", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async update() {
        let response = await axios.put("tpv/customer", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async delete() {
        let response = await axios.delete("tpv/customer/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}