<template>
    <div style="position: relative; display: inline-block;" :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <div>
            <InputText 
            ref="searchInput"
            style="width: 100%"
            :autocomplete="autocomplete"
            :placeholder="placeholder" 
            :type="type"
            :disabled="disabled"
            v-model="localValue" 
            :readonly="readonly"
            :class="{ 'p-invalid': valid === false }"
            @input="onInput($event)"
            @keydown.enter.exact="onEnter($event.target.value)"
            @keydown.shift.tab.exact="$emit('shift-tab')"
            @keydown.tab="$emit('tab')"
            @blur="onBlur($event.target.value)"
            @focus="onFocus($event.target.value)"
            @keydown.down.exact="enterList($event)"
            />
        </div>
        <div v-if="results > 0"  class="autocomplete-items">
            <ul>
                <li  v-for="(result , index) in list" 
                tabindex="0" 
                :key="result" 
                :ref="'result' + index"
                @click="select(result)"
                @keydown.enter.exact="select(result, 'enter', $event)"
                @keydown.down.exact="next(index, $event)"
                @keydown.up.exact="back(index, $event)" >
                <span v-html="getText(result)"></span>
            </li>
            </ul>
        </div>
        <small class="p-invalid" v-if="valid === false"> 
            {{validation}}
        </small>
    </div>
</template>

<script>

export default {
  emits: ['update:modelValue', 'input', 'selected'],
  expose: ['clear'],
  props: {
    items: {
        type: [Array, null],
        default: () => ['001','003', '005', '006']
    },
    optionValue: {
        type: [String, null],
        default: null
    },
    optionText: {
        type: [String, null],
        default: null
    },
    optionSearch: {
        type: [String, null],
        default: null
    },
    tooltip: {
        type: [String, null],
        default: null
    },
    placeholder: {
        type: [String, null],
        default: null
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    readonly:{
        type: [Boolean, null],
        default: null
    },
    label: {
        type: [String, null],
        default: null
    },
    type: {
        type: [String, null],
        default: "text"
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
    disabled: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String,
        default: "startsWith"
    },
    valueProp: {
        type: String,
        default: "id"
    },
    maxItems: {
        type: Number,
        default: 10
    },
    optionRequired: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      localValue: this.modelValue,
      internalValue: null,
      list: []
    };
  },
  methods: {
    clear() {
        this.localValue = null;
    },
    onBlur(val) {
        //Debemos quitar el listado
        // this.list = [];
        if (this.items.find(x => x == val) == null && this.optionRequired) {
            this.localValue = null;
        }
    },
    onFocus() {
        //Volvemosm a abrir opciones
        // this.search(val);
    },
    onEnter(value) {
        this.list = [];
        this.$emit("enter", value);        
    },
    onDown() {
        this.list = [];
        this.$emit('down');
    },
    onUp() {
        this.list = [];
        this.$emit('up');
    },
    onLeft() {
        this.list = [];
        this.$emit('left');
    },
    onRight() {
        this.list = [];
        this.$emit('right');
    },
    enterList(e) {
        e.preventDefault();
        if (this.results > 0) {
            this.$refs.result0.focus();
        }else {
            this.onDown();
        }
    },
    next(index, e) {
        e.preventDefault();
        if (index != (this.list.length - 1)) {
            this.$refs["result" + (index + 1)].focus();
        }
    },
    back(index, e) {
        e.preventDefault();
        if (index == 0) {
            this.$refs.searchInput.$el.focus();   
        }else {
            this.$refs["result" + (index - 1)].focus();
        }
    },
    select(res, control) {
        if(this.optionValue || this.optionText)
            this.localValue = res[this.optionValue ?? this.optionText];
        else
        this.localValue = res;
        this.list = [];
        if(control != "enter")
            this.$refs.searchInput.$el.focus();

        this.$emit("selected", res);
        this.$emit("update:modelValue", this.localValue);
    },
    onInput(evt) {
      this.$emit("update:modelValue", evt.target.value);
      if (evt.target.value.length < 1)
            this.list = [] 
        else
            this.search(evt.target.value);            
    },
    getText(result) {
        if(this.optionValue || this.optionText)
            return result[this.optionText ?? this.optionValue];
        return this.mode == "startsWith" ? result.replace(result.slice(0, this.localValue.length), "<b>" + result.slice(0, this.localValue.length) + "</b>") 
        : result;
    },  
    focus() {
      this.$refs.searchInput.$el.focus()
    },
    search(searchValue) {
        if (this.mode == "startsWith") {
                this.list = this.items.filter(item => {
                    if (this.optionSearch || this.optionValue) 
                        return (item[this.optionSearch ?? this.optionValue] ?? "").toLowerCase().startsWith((searchValue ?? "").toLowerCase())
                    return (item ?? "").toLowerCase().startsWith((searchValue ?? "").toLowerCase())
                })
            }else if (this.mode == "contains") {
                this.list = this.items.filter(item => {
                    if (this.optionValue || this.optionSearch) 
                        return (item[this.optionSearch ?? this.optionValue] ?? "").toLowerCase().includes((searchValue ?? "").toLowerCase())
                    return (item ?? "").toLowerCase().includes((searchValue ?? "").toLowerCase())
                })
            }
            this.completeList = this.list.length;
            this.list = this.list.slice(0, this.maxItems);
    }
  },
  computed: {
        results() {
            return this.list.length;
        }
    },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>
  .custom-alert .p-tooltip-text {
    background-color: var(--blue-900);
    color: rgb(255, 255, 255);
  }
  .custom-alert.p-tooltip-top .p-tooltip-arrow {
      border-right-color: var(--pblue-900);
  }

  #searchInput {
    border: none;
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.autocomplete-items div {
  /* padding: 10px; */
  cursor: pointer;
  background-color: white;
  color: black;
}

.autocomplete-items ul {
    background-color: white;
    color: black;
    list-style-type: none;
    list-style-position:inside;
    margin:0;
    padding:0;
    text-align: left; 
    font-family: inherit;
}

.autocomplete-items ul li {
    cursor: pointer;
    width: 100%;
    padding: .5rem;
}

.autocomplete-items ul li:hover {
    background-color: rgb(76, 76, 197);
    color: white;
}

.autocomplete-items ul li:focus {
    background-color: rgb(76, 76, 197);
    color: white;
    border-color: rgb(45, 45, 148);;
}

.autocomplete-input input{
    border: none;
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.autocomplete-input input:focus {
  /*when hovering an item:*/
  border: none;
  background-color: DodgerBlue;
  outline: none;
}

.autocomplete-input input:hover {
  /*when hovering an item:*/
  border: none;
  background-color: DodgerBlue;
  outline: none;
}

</style>